import { DisplayType, PositionType } from "@types"

export function getXsDisplayClass(display: DisplayType) {
  switch (display) {
    case "block":
      return "xs:block"
    case "flex":
      return "xs:flex"
    case "flow-root":
      return "xs:flow-root"
    case "grid":
      return "xs:grid"
    case "hidden":
      return "xs:hidden"
    case "inline":
      return "xs:inline"
    case "inline-block":
      return "xs:inline-block"
    case "inline-flex":
      return "xs:inline-flex"
    case "inline-grid":
      return "xs:inline-grid"
    case "table":
      return "xs:table"
    default:
      return ""
  }
}

export function getSmDisplayClass(display: DisplayType) {
  switch (display) {
    case "block":
      return "sm:block"
    case "flex":
      return "sm:flex"
    case "flow-root":
      return "sm:flow-root"
    case "grid":
      return "sm:grid"
    case "hidden":
      return "sm:hidden"
    case "inline":
      return "sm:inline"
    case "inline-block":
      return "sm:inline-block"
    case "inline-flex":
      return "sm:inline-flex"
    case "inline-grid":
      return "sm:inline-grid"
    case "table":
      return "sm:table"
    default:
      return ""
  }
}

export function getMdDisplayClass(display: DisplayType) {
  switch (display) {
    case "block":
      return "md:block"
    case "flex":
      return "md:flex"
    case "flow-root":
      return "md:flow-root"
    case "grid":
      return "md:grid"
    case "hidden":
      return "md:hidden"
    case "inline":
      return "md:inline"
    case "inline-block":
      return "md:inline-block"
    case "inline-flex":
      return "md:inline-flex"
    case "inline-grid":
      return "md:inline-grid"
    case "table":
      return "md:table"
    default:
      return ""
  }
}

export function getLgDisplayClass(display: DisplayType) {
  switch (display) {
    case "block":
      return "lg:block"
    case "flex":
      return "lg:flex"
    case "flow-root":
      return "lg:flow-root"
    case "grid":
      return "lg:grid"
    case "hidden":
      return "lg:hidden"
    case "inline":
      return "lg:inline"
    case "inline-block":
      return "lg:inline-block"
    case "inline-flex":
      return "lg:inline-flex"
    case "inline-grid":
      return "lg:inline-grid"
    case "table":
      return "lg:table"
    default:
      return ""
  }
}

export function getXlDisplayClass(display: DisplayType) {
  switch (display) {
    case "block":
      return "xl:block"
    case "flex":
      return "xl:flex"
    case "flow-root":
      return "xl:flow-root"
    case "grid":
      return "xl:grid"
    case "hidden":
      return "xl:hidden"
    case "inline":
      return "xl:inline"
    case "inline-block":
      return "xl:inline-block"
    case "inline-flex":
      return "xl:inline-flex"
    case "inline-grid":
      return "xl:inline-grid"
    case "table":
      return "xl:table"
    default:
      return ""
  }
}

export function getXsPositionClass(position: PositionType) {
  switch (position) {
    case "absolute":
      return "xs:absolute"
    case "fixed":
      return "xs:fixed"
    case "relative":
      return "xs:relative"
    case "static":
      return "xs:static"
    case "sticky":
      return "xs:sticky"
    default:
      return ""
  }
}

export function getSmPositionClass(position: PositionType) {
  switch (position) {
    case "absolute":
      return "sm:absolute"
    case "fixed":
      return "sm:fixed"
    case "relative":
      return "sm:relative"
    case "static":
      return "sm:static"
    case "sticky":
      return "sm:sticky"
    default:
      return ""
  }
}

export function getMdPositionClass(position: PositionType) {
  switch (position) {
    case "absolute":
      return "md:absolute"
    case "fixed":
      return "md:fixed"
    case "relative":
      return "md:relative"
    case "static":
      return "md:static"
    case "sticky":
      return "md:sticky"
    default:
      return ""
  }
}

export function getLgPositionClass(position: PositionType) {
  switch (position) {
    case "absolute":
      return "lg:absolute"
    case "fixed":
      return "lg:fixed"
    case "relative":
      return "lg:relative"
    case "static":
      return "lg:static"
    case "sticky":
      return "lg:sticky"
    default:
      return ""
  }
}

export function getXlPositionClass(position: PositionType) {
  switch (position) {
    case "absolute":
      return "xl:absolute"
    case "fixed":
      return "xl:fixed"
    case "relative":
      return "xl:relative"
    case "static":
      return "xl:static"
    case "sticky":
      return "xl:sticky"
    default:
      return ""
  }
}

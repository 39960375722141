import styled from "styled-components";
import tw from "tailwind-styled-components";

import { BoxProps } from "./box";
import {
  getXsDisplayClass,
  getXsPositionClass,
  getSmDisplayClass,
  getSmPositionClass,
  getMdDisplayClass,
  getMdPositionClass,
  getLgDisplayClass,
  getLgPositionClass,
  getXlDisplayClass,
  getXlPositionClass,
} from "./box.helpers";

const StyledBox = styled.div<BoxProps>`
  ${(p) => (p.styledCss ? p.styledCss : "")}

  @media only screen and (max-width: 2500px) {
    ${(p) => (p.xl?.styledCss ? p.xl.styledCss : "")}
  }

  @media only screen and (max-width: 1366px) {
    ${(p) => (p.lg?.styledCss ? p.lg.styledCss : "")}
  }

  @media only screen and (max-width: 1024px) {
    ${(p) => (p.md?.styledCss ? p.md.styledCss : "")}
  }

  @media only screen and (max-width: 768px) {
    ${(p) => (p.sm?.styledCss ? p.sm.styledCss : "")}
  }

  @media only screen and (max-width: 425px) {
    ${(p) => (p.xs?.styledCss ? p.xs.styledCss : "")}
  }
`;

export const Box = tw(StyledBox)`
  ${(p) => (p.display ? p.display : "")}
  ${(p) => (p.position ? p.position : "")}

  ${(p) => (p.xs?.display ? getXsDisplayClass(p.xs.display) : "")}
  ${(p) => (p.xs?.position ? getXsPositionClass(p.xs.position) : "")}

  ${(p) => (p.sm?.display ? getSmDisplayClass(p.sm.display) : "")}
  ${(p) => (p.sm?.position ? getSmPositionClass(p.sm.position) : "")}

  ${(p) => (p.md?.display ? getMdDisplayClass(p.md.display) : "")}
  ${(p) => (p.md?.position ? getMdPositionClass(p.md.position) : "")}

  ${(p) => (p.lg?.display ? getLgDisplayClass(p.lg.display) : "")}
  ${(p) => (p.lg?.position ? getLgPositionClass(p.lg.position) : "")}

  ${(p) => (p.xl?.display ? getXlDisplayClass(p.xl.display) : "")}
  ${(p) => (p.xl?.position ? getXlPositionClass(p.xl.position) : "")}
`;
